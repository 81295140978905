import { detectLanguage } from '../../services/helpers';

// Keep synced with ./index.js since we need this on two places to prevent circular dependencies.

const routesSetup = [
  {
    name: 'Index',
    paths: {
      sv: '',
      dk: '',
      no: '',
      fi: '',
      en: '',
      de: '',
      cs: ''
    }
  },
  {
    name: 'Activate',
    paths: {
      sv: 'aktivera-kort',
      dk: 'aktiver-kort',
      no: 'aktivere-kort',
      fi: 'aktivoi-kortti',
      en: 'activate-card',
      de: 'karte-aktivieren',
      cs: 'activate-card'
    }
  },
  {
    name: 'ActivateAdidas',
    paths: {
      sv: 'aktivera-kort/adidas',
      dk: 'aktiver-kort/adidas',
      no: 'aktivere-kort/adidas',
      en: 'activate-card/adidas',
      de: 'activate-card/adidas'
    }
  },
  {
    name: 'ActivatePlus',
    paths: {
      sv: 'aktivera-kort/plus',
      dk: 'aktiver-kort/plus',
      no: 'aktivere-kort/plus',
      fi: 'aktivoi-kortti/plus',
      en: 'activate-card/plus',
      de: 'activate-card/plus',
      cs: 'activate-card/plus'
    }
  },
  {
    name: 'Webshop',
    paths: {
      sv: 'webshop',
      dk: 'webshop',
      no: 'webshop',
      fi: 'verkkokauppa',
      en: 'webshop',
      de: 'webshop',
      cs: 'objednat'
    }
  },
  {
    name: 'GolfCourses',
    paths: {
      sv: 'golfbanor',
      dk: 'golfbaner',
      no: 'golfbaner',
      fi: 'golfkentät',
      en: 'golf-courses',
      de: 'golf-courses',
      cs: 'golf-courses'
    }
  },
  {
    name: 'Map',
    paths: {
      sv: 'karta',
      dk: 'kort',
      no: 'kart',
      fi: 'kartta',
      en: 'map',
      de: 'karte',
      cs: 'mapa'
    }
  },
  {
    name: 'Contest',
    paths: {
      sv: 'tavla',
      dk: 'konkurrence/upc',
      no: 'konkurranse',
      fi: null,
      en: 'contest',
      de: null,
      cs: null
    }
  },
  {
    name: 'Compete',
    paths: {
      sv: 'tavla',
      dk: 'konkurrence',
      no: 'konkurranse',
      fi: null,
      en: 'contest',
      de: null,
      cs: null
    }
  },
  {
    name: 'Trophy',
    paths: {
      sv: 'tavla/trophy',
      dk: 'konkurrence/trophy',
      no: 'konkurranse/trophy',
      fi: null,
      en: 'contest/trophy',
      de: null,
      cs: null
    }
  },
  {
    name: 'Upc',
    paths: {
      sv: 'tavla/upc',
      dk: 'konkurrence/upc',
      no: 'tavla/upc',
      fi: null,
      en: 'tavla/upc',
      de: null,
      cs: null
    }
  },
  {
    name: 'IndoorOpen',
    paths: {
      sv: 'tavla/indoor-open',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    name: 'MinHusbilOpen',
    paths: {
      sv: 'tavla/minhusbil-open',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    name: 'Contests',
    paths: {
      sv: 'tavla/program',
      dk: 'konkurrence/program',
      no: 'konkurranse/skjmea',
      fi: null,
      en: 'contest/program',
      de: null,
      cs: null
    }
  },
  {
    name: 'TrophyPartners',
    paths: {
      sv: 'tavla/trophy/partners',
      dk: 'konkurrence/trophy/partnere',
      no: 'konkurranse/trophy/partners',
      fi: null,
      en: 'contest/trophy/partners',
      de: null,
      cs: null
    }
  },
  {
    name: 'UpcPartners',
    paths: {
      sv: 'tavla/upc/partners',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    name: 'TrophyBroHof',
    paths: {
      sv: 'tavla/trophy/final',
      dk: 'konkurrence/trophy/finale',
      no: 'konkurranse/trophy/finale',
      fi: null,
      en: 'contest/trophy/final',
      de: null,
      cs: null
    }
  },
  {
    name: 'ContestOrderOfMerit',
    paths: {
      sv: 'tavla/order-of-merit',
      dk: 'konkurrence/order-of-merit',
      no: 'konkurranse/order-of-merit',
      fi: null,
      en: 'contest/order-of-merit',
      de: null,
      cs: null
    }
  },
  {
    name: 'Final',
    paths: {
      sv: 'tavla/final',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    name: 'Plus',
    paths: {
      sv: 'plus',
      dk: 'plus',
      no: 'plus',
      fi: 'plus',
      en: 'plus',
      de: 'plus',
      cs: 'plus'
    }
  },
  {
    name: 'Account',
    paths: {
      sv: 'min-golf',
      dk: 'min-golf',
      no: 'min-golf',
      fi: 'oma-golf',
      en: 'my-golf',
      de: 'my-golf',
      cs: 'my-golf'
    }
  },
  {
    name: 'Login',
    paths: {
      sv: 'min-golf/logga-in',
      dk: 'min-golf/log-ind',
      no: 'min-golf/logg-in',
      fi: 'oma-golf/kirjaudu-sisään',
      en: 'my-golf/login',
      de: 'my-golf/login',
      cs: 'my-golf/login'
    }
  },
  {
    name: 'News',
    paths: {
      sv: 'nyheter',
      dk: 'nyheder',
      no: 'nyheter',
      fi: 'uutiset',
      en: 'news',
      de: 'neuigkeiten',
      cs: 'news'
    }
  },
  {
    name: 'Faq',
    paths: {
      sv: 'faq',
      dk: 'faq',
      no: 'faq',
      fi: 'faq',
      en: 'faq',
      de: 'faq',
      cs: 'faq'
    }
  },
  {
    name: 'About',
    paths: {
      sv: 'om-oss',
      dk: 'om-os',
      no: 'om-oss',
      fi: 'meistä',
      en: 'about',
      de: 'about',
      cs: 'about'
    }
  },
  {
    name: 'Retailers',
    paths: {
      sv: 'aterforsaljare',
      dk: 'forhandler',
      no: 'forhandler',
      fi: 'jälleenmyyjät',
      en: null,
      de: null,
      cs: null
    }
  },
  {
    name: 'Contact',
    paths: {
      sv: 'kontakt',
      dk: 'kontakt',
      no: 'kontakt',
      fi: 'kontakti',
      en: 'contact',
      de: 'contact',
      cs: 'contact'
    }
  },
  {
    name: 'Press',
    paths: {
      sv: 'press',
      dk: 'press',
      no: 'press',
      fi: 'lehdistö',
      en: 'press',
      de: 'press',
      cs: 'press'
    }
  },
  {
    name: 'Terms',
    paths: {
      sv: 'kopvillkor',
      dk: 'vilkar',
      no: 'vilkar',
      fi: 'ehdot',
      en: 'terms',
      de: 'terms',
      cs: 'nakupni'
    }
  },
  {
    name: 'Privacy',
    paths: {
      sv: 'integritetspolicy',
      dk: 'privatlivspolitik',
      no: 'personvern',
      fi: 'tietosuojakäytäntö',
      en: 'privacy-policy',
      de: 'privacy-policy',
      cs: 'privacy-policy'
    }
  },
  {
    name: 'Cookies',
    paths: {
      sv: 'cookies',
      dk: 'cookies',
      no: 'cookies',
      fi: 'evästeet',
      en: 'cookies',
      de: 'cookies',
      cs: 'cookies'
    }
  },
  {
    name: 'Recommend',
    paths: {
      sv: 'rekommendera',
      dk: 'anbefale',
      no: 'anbefale',
      fi: 'suositella',
      en: 'recommend',
      de: null,
      cs: null
    }
  },
  {
    name: 'GolfClub',
    paths: {
      sv: 'golfklubb',
      dk: 'golfklub',
      no: 'golfklubb',
      fi: 'Golfklubi',
      en: 'golf-club',
      de: 'golf-club',
      cs: 'golf-club'
    }
  },
  {
    name: 'Survey',
    paths: {
      sv: 'undersokning',
      dk: 'undersøgelse',
      no: 'undersøkelse',
      fi: 'arviot',
      en: 'survey',
      de: 'survey',
      cs: 'survey'
    }
  },
  {
    name: 'HowItWorks',
    paths: {
      sv: 'sa-fungerar-det',
      dk: 'sådan-fungerer-det',
      no: 'slik-det-fungerer',
      fi: 'niin-se-toimii',
      en: 'how-it-works',
      de: 'so-funktioniert-es',
      cs: 'tak-to-funguje'
    }
  },
  {
    name: 'Campaign',
    paths: {
      sv: 'kampanj',
      dk: 'kampagne',
      no: 'kampanje',
      fi: 'kampanja',
      en: 'campaign',
      de: 'kampagne',
      cs: ''
    }
  },
  {
    name: 'Campaignp',
    paths: {
      sv: 'kampanjp',
      dk: 'kampagnep',
      no: 'kampanjep',
      fi: 'kampanjap',
      en: 'campaignp',
      de: 'kampagnep',
      cs: ''
    }
  },
  {
    name: 'Deals',
    paths: {
      sv: 'deals',
      dk: 'deals',
      no: '',
      fi: '',
      en: '',
      de: '',
      cs: ''
    }
  },
  {
    name: 'SeasonOpen',
    paths: {
      sv: 'golfsäsongen-2025',
      dk: 'golfsæson-2025',
      no: 'golfsesong-2025',
      fi: '',
      en: 'golf-season-2025',
      de: 'golf-saison-2025',
      cs: ''
    }
  },
  {
    name: 'SASEuroBonusPoints',
    paths: {
      sv: 'partners/sas-eurobonus',
      dk: 'partners/sas-eurobonus',
      no: 'partners/sas-eurobonus',
      fi: '',
      en: '',
      de: '',
      cs: ''
    }
  },
  {
    name: 'CustomerClub',
    paths: {
      sv: 'kund-klubb',
      dk: 'kunde-klub',
      no: 'kunde-klubb',
      fi: '',
      en: 'customer-club',
      de: 'kunden-club',
      cs: ''
    }
  },
  // new Golfamore Events
  {
    name: 'Events',
    paths: {
      sv: 'events',
      dk: 'konkurrence/upc',
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    name: 'Competitions',
    paths: {
      sv: 'events/tävlingar',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    name: 'CompetitionGolfamoreTrophy',
    paths: {
      sv: 'events/golfamore-trophy',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    name: 'CompetitionUnderParChallenge',
    paths: {
      sv: 'events/under-par-challenge',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    name: 'CompetitionFootJoyMasters',
    paths: {
      sv: 'events/footjoy-masters',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    name: 'CompetitionIndoorOpen',
    paths: {
      sv: 'events/indoor-open',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    name: 'Calendar',
    paths: {
      sv: 'events/tavlingskalendern',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    name: 'OrderOfMerit',
    paths: {
      sv: 'events/order-of-merit',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    name: 'Tours',
    paths: {
      sv: 'events/on-tour',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    name: 'Extreme',
    paths: {
      sv: 'events/extreme',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    name: 'TourBroHof',
    paths: {
      // sv: 'events/on-tour-bro-hof',
      sv: 'webshop',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    name: 'TourTheNational',
    paths: {
      // sv: 'events/on-tour-the-national',
      sv: 'webshop',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    name: 'TourVallda',
    paths: {
      // sv: 'events/on-tour-vallda',
      sv: 'webshop',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    name: 'TourOsteraker',
    paths: {
      // sv: 'events/on-tour-osteraker',
      sv: 'webshop',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    name: 'TourHalmstad',
    paths: {
      // sv: 'events/on-tour-halmstad',
      sv: 'webshop',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    name: 'I',
    paths: {
      // sv: 'events/on-tour-halmstad',
      sv: 'i',
      dk: 'i',
      no: 'i',
      fi: null,
      en: 'i',
      de: 'i',
      cs: null
    }
  },
  {
    name: 'On Tour',
    paths: {
      sv: 'ontour',
      dk: null,
      no: null,
      fi: null,
      en: null,
      de: null,
      cs: null
    }
  },
  {
    name: 'Redirect',
    paths: {
      sv: 'r',
      dk: 'r',
      no: 'r',
      fi: 'r',
      en: 'r',
      de: 'r',
      cs: ''
    }
  }
];

const languageCode = detectLanguage();

const paths = routesSetup.reduce((add, cur) => {
  add[cur.name] = `/${languageCode}/${cur.paths[languageCode]}/`;
  return add;
}, {});

const getPath = (lang, route) =>
  paths[route] ? paths[route] : new Error(`Path could not be found for route ${route} in language ${lang}`);

export default getPath;
